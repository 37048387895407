<script setup>
import { computed, useSlots } from 'vue'

defineProps({
  label: {
    type: String,
    default: null
  },
  help: {
    type: String,
    default: null
  },
  danger: {
    type: String,
    default: null
  },
  upperText:Boolean,
  labelMinWidth:{
    type:String,
    default:"[30%]"
  }
})

const slots = useSlots()

const wrapperClass = computed(() => {
  const base = []
  const slotsLength = slots.default().length

  if (slotsLength > 1) {
    base.push('grid grid-cols-1 gap-3')
  }

  if (slotsLength === 2) {
    base.push('md:grid-cols-2')
  }

  return base
})
</script>

<template>
  <div class="mb-2 last:mb-0">
    <div :class="upperText? '':'flex flex-col sm:flex-row billing-address'" v-if="label">
      <label
          class="flex items-center font-normal"
          :class="upperText? 'w-full':'sm:w-'+labelMinWidth+' text-tiny sm:text  mr-4'"
      >{{ label }}</label>
      <div :class="[wrapperClass,upperText? 'w-full':'grow']">
        <slot />
      </div>
    </div>
    <div v-else :class="wrapperClass">
        <slot />
    </div>
    <div
      v-if="help"
      class="text-xs text-gray-500 dark:text-gray-400 mt-1"
    >
      {{ help }}
    </div>
    <div
      v-if="danger"
      class="text-xs text-red-600 dark:text-red-400 mt-1"
    >
      {{ danger }}
    </div>
  </div>
</template>
